@font-face {
  font-family: 'Open Sans';
  src: url('./OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('./OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('./OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('./Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('./Montserrat-Bold.ttf');
}

html,
body {
  font-family: 'Open Sans' !important;
}

strong {
  font-family: 'Open Sans Bold' !important;
}
